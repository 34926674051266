/* Section3.css */

.section3-container {
    padding: 60px 20px;
    background-color: #ffffff;      /* Light background */
    text-align: center;             /* Centers the title */
  }
  
  .section3-title {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
    position: relative;
  }
  
  .section3-content {
    display: flex;
    justify-content: space-around;  /* Distribute the items horizontally */
    gap: 20px;                      /* Add some space between the items */
    flex-wrap: wrap;                /* Wrap items on smaller screens */
  }
  
  .section3-item {
    flex: 1 1 45%;                  /* Flex-grow, flex-shrink, and basis (for responsiveness) */
    max-width: 400px;               /* Set a maximum width for each item */
    text-align: center;
    padding: 20px;
  }
  
  .section3-icon {
    width: auto;                   /* Set the icon size */
    height: 100px;
    margin-bottom: 20px;
  }
  
  .section3-subtitle {
    font-size: 1.5rem;
    color: #0033cc;                 /* Set color for subtitle */
    margin-bottom: 10px;
  }
  
  .section3-description {
    font-size: 1rem;
    color: #666;
  }
  
  /* Media Queries for responsiveness */
  
  @media (max-width: 768px) {
    .section3-content {
      flex-direction: column;       /* Stack the items vertically */
      align-items: center;
    }
  
    .section3-item {
      flex: 1 1 100%;
    }
  }
  