/* Section2.css */

.section2-container {
    padding: 60px 20px;           /* Padding for spacing around the content */
    background-color: #f9f9f9;    /* A light background color to contrast with other sections */
    text-align: center;           /* Center align text */
  }
  
  .section2-title {
    font-size: 3rem;              /* Large font size for the title */
    font-weight: bold;            /* Bold text for emphasis */
    color: #333;                  /* Dark color for better readability */
    margin-bottom: 20px;          /* Space below the title */
  }
  
  .section2-text {
    font-size: 1.25rem;           /* Medium font size for the paragraph */
    line-height: 1.6;             /* Increase line height for better readability */
    color: #666;                  /* Slightly lighter color for the paragraph text */
    max-width: 800px;             /* Limit text width for better readability */
    margin: 0 auto;               /* Center the text block */
  }
  
  @media (max-width: 768px) {
    .section2-title {
      font-size: 2.5rem;          /* Adjust title size for smaller screens */
    }
  
    .section2-text {
      font-size: 1rem;            /* Adjust paragraph text size for smaller screens */
    }
  }
  