/* Section4.css */

.section4-container {
    padding: 60px 20px;
    background-color: #f9f9f9;    /* Light background color */
    text-align: center;
  }
  
  .section4-title {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
    position: relative;
  }
  
  .section4-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);  /* Create 5 equal columns */
    gap: 40px;                              /* Space between columns */
    text-align: left;                       /* Align text to the left */
  }
  
  .section4-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;                 /* Left-align items in each column */
  }
  
  .section4-category {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0033cc;                         /* Use the blue color for categories */
    margin-bottom: 15px;
  }
  
  .section4-list {
    list-style-type: none;                   /* Remove bullets from list */
    padding: 0;                              /* Remove default padding */
  }
  
  .section4-list li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  /* Media Queries for responsive design */
  
  @media (max-width: 1200px) {
    .section4-grid {
      grid-template-columns: repeat(3, 1fr); /* Switch to 3 columns on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .section4-grid {
      grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .section4-grid {
      grid-template-columns: 1fr;            /* Stack into 1 column on mobile */
    }
  }
  