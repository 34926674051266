.App {
    text-align: center;
}
/* Media queries to handle smaller screens */
@media (max-width: 768px) {
    .App {
        max-width: 100%;           /* Make the image even smaller on mobile screens */
        padding: 0px;
        margin: 0px;
      }
  }
  
  @media (max-width: 480px) {
    .App {
        max-width: 100%;           /* Make the image even smaller on mobile screens */
        padding: 0px;
        margin: 0px;
    }
  }