/* ContactForm.css */

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;    /* The form will have a maximum width of 600px */
    margin: 40px auto;   /* Centers the form on the page */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .form-title {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
    position: relative;
    text-align: center;
  }
  
  .form-label {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;         /* Full width for input fields */
    padding: 12px 0px 12px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .form-textarea {
    height: 150px;       /* Default height for the textarea */
    resize: vertical;    /* Allow resizing only vertically */
  }
  
  .form-submit {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-submit:hover {
    background-color: #0056b3;
  }
  
  /* Add media queries to make the form mobile-friendly */
  
  @media (max-width: 768px) {
    .contact-form {
      padding: 15px;              /* Less padding on smaller screens */
    }
  
    .form-label {
      font-size: 14px;             /* Slightly smaller label text */
    }
  
    .form-input,
    .form-textarea {
      font-size: 16px;             /* Keep inputs large for touch interaction */
      padding: 10px 0px 10px, 0px;               /* Adjust padding to make input fields look better */
    }
  
    .form-submit {
      font-size: 14px;             /* Smaller button font on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .contact-form {
      padding: 10px;              /* Even less padding on very small screens */
      margin: 20px auto;          /* Less margin for better use of space */
    }
  
    .form-submit {
      width: 100%;                /* Make the submit button full width on mobile */
      padding: 15px 0px 15px 0px;              /* Increase button padding for easier touch */
    }
  
    .form-label {
      font-size: 12px;            /* Smaller font for labels */
    }
  }
  