/* Section1.css */

.section1-container {
    display: flex;
    justify-content: center;   /* Centers the image horizontally */
    align-items: center;       /* Centers the image vertically */
    padding: 0px;
    background-color: #f9f9f9; /* Add a light background color to contrast the white logo */
  }
  
  .video-player {
    width: 100%;               /* Makes the video take up the full width of the container */
    max-width: 1280px;           /* Limits the maximum width of the video */
    border-radius: 8px;         /* Optional: Adds rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow */
    padding: 20px;
  }
  
  /* Media queries to handle smaller screens */
  @media (max-width: 768px) {
    .logo-image {
      max-width: 110%;           
    }
    .video-player {
      padding: 0px;
      border-radius: 0px;
      }
  }
  
  @media (max-width: 480px) {
    .logo-image {
      max-width: 100%;           /* Make the image even smaller on mobile screens */
    }
    .video-player {
    padding: 0px;
    border-radius: 0px;
    }
  }
  