/* Section5.css */

.section5-container {
    display: flex;
    justify-content: center;    /* Center horizontally */
    align-items: center;        /* Center vertically */
    height: 50%;               /* Set the height of the section */
    background-color: #ffffff;  /* Light gray background */
    text-align: center;
    padding: 10px;
  }
  
  .banner {
    display: flex;
    flex-direction: column;     /* Stack the text and image vertically */
    align-items: center;
    gap: 5px;
  }
  
  .section5-title {
    font-size: 3rem;          /* Large font for the title */
    font-weight: bold;
    color: #000000;             /* Orange color to highlight the construction state */
  }

  .section5-subtitle {
    font-size: 1rem;          /* Large font for the title */
    font-weight: bold;
    color: #000000;             /* Orange color to highlight the construction state */
  }
  
  .section5-image {
    max-width: 800px;           /* Limit the width of the image */
    width: 100%;                /* Make the image responsive */
    height: auto;
    align-self: center;
    
  }
  
  /* Media Queries for smaller screens */
  
  @media (max-width: 768px) {
    .section5-title {
      font-size: 2rem;          /* Adjust the font size for smaller screens */
    }
  
    .section5-image {
      max-width: 300px;         /* Limit the image size on smaller devices */
    }
  }
  
  @media (max-width: 480px) {
    .section5-title {
      font-size: 1.75rem;       /* Further adjust font size for mobile devices */
    }
  
    .section5-image {
      max-width: 250px;         /* Limit the image size on very small devices */
    }
  }
  